import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";

@inject("ticketStore", "sessionStore", "navigationStore", "userStore")
@observer
class TicketList extends Component {
  async componentDidMount() {
    try {
      if (this.props.sessionStore.initialized) {
        let { t } = this.props;
        document.title = "Ticket - List";
      }
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  }

  componentWillUnmount() {
    try {
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  }

  render() {
    const { t } = this.props;
    console.log(this.props.ticketStore.count);
    if (this.props.ticketStore.loading === false) {
      return (
        <>
          <p>{this.props.ticketStore.count[0] + " Requests"}</p>
          <table style={{ maxWidth: "90%", minWidth: "90%", width: "90rem" }}>
            <thead>
              <tr>
                <th>Key</th>
                <th>Summary</th>
                <th>Support Level</th>
                <th>Assignee</th>
                <th>Status</th>
                <th>Create</th>
                <th>Time to resolution</th>
                <th>Priority</th>
              </tr>
            </thead>
            <tbody>
              {this.props.ticketStore.tickets
                ? this.props.ticketStore.tickets.map((ticket, i) => {
                    return (
                      <tr
                        key={ticket._id + i}
                        onClick={() => {
                          this.props.ticketStore.list = false;
                          this.props.ticketStore.getTicket(ticket._id);
                        }}
                      >
                        <td>{ticket._id}</td>
                        <td>{ticket.subject}</td>
                        <td>{ticket.level}</td>
                        <td>
                          {ticket.assignedUser ? (
                            ticket.assignedUser.username ? (
                              ticket.assignedUser.favourite ? (
                                <>
                                  {ticket.assignedUser.username}
                                  <svg
                                    width="20"
                                    height="36"
                                    id="fas_fa-star"
                                    data-name="fas fa-star"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 66.62 63.69"
                                    className={"ticket-title-buttons activeFav"}
                                    style={{"marginLeft":"1rem"}}
                                  >
                                    <path
                                      className="cls-1"
                                      d="M51.87,69.13c-.78-.45-1.57-.86-2.38-1.25A16.65,16.65,0,0,0,47.87,67c-1.3-.71-2.61-1.41-3.93-2.06-.72-.41-1.45-.79-2.19-1.15s-1.19-.67-1.82-.94a14.72,14.72,0,0,0-1.76.92c-.77.36-1.52.75-2.25,1.18-1.33.65-2.63,1.35-3.93,2a15.78,15.78,0,0,0-1.47.77c-.89.44-1.77.89-2.63,1.39-1.32.65-2.63,1.33-3.92,2a11.05,11.05,0,0,1-1.23.52,4,4,0,0,1-4.54-2.17,3.68,3.68,0,0,1-.42-1.69c0-.28.07-.55.12-.82.17-1.06.35-2.12.53-3.18l.12-.61c.18-1.13.37-2.26.55-3.39.28-1.3.48-2.62.7-3.94a10,10,0,0,0,.25-1.74s.06-.07.07-.11c.12-.72.23-1.43.35-2.15.17-.9.33-1.81.5-2.72a.26.26,0,0,0-.1-.25L20,48.14l-.2-.2c-1.23-1.23-2.47-2.45-3.74-3.64a2.24,2.24,0,0,0-.44-.43c-1.16-1.19-2.37-2.33-3.57-3.48a1.44,1.44,0,0,0-.38-.36C10.46,38.84,9.27,37.65,8,36.51L7.56,36a4.09,4.09,0,0,1-.71-4,4.53,4.53,0,0,1,1.26-1.73,4.46,4.46,0,0,1,2.06-.82L12,29.18c1.34-.16,2.67-.36,4-.59,1.11-.12,2.2-.28,3.3-.48A1.78,1.78,0,0,0,20,28l1.18-.14L24,27.43c1.34-.16,2.68-.35,4-.58a.28.28,0,0,0,.32-.17c.4-.84.81-1.68,1.21-2.51.71-1.35,1.37-2.72,2-4.09A8.26,8.26,0,0,0,32.06,19c.53-.95,1-1.93,1.45-2.92l1.94-3.92L36,11a6.53,6.53,0,0,1,1.12-1.72A3.92,3.92,0,0,1,40.55,8.2a1.06,1.06,0,0,0,.25,0,4.12,4.12,0,0,1,2.34,1.55c.29.4.46.88.73,1.29a9.47,9.47,0,0,0,.54,1.11c.63,1.35,1.3,2.68,2,4,.45,1,1,2,1.46,3l.51,1,1.92,3.92c.42.85.84,1.7,1.25,2.56.07.14.16.23.33.2,1.34.23,2.67.42,4,.58.37.06.74.13,1.11.18l2.9.4a4.33,4.33,0,0,0,1,.15c1,.19,1.93.33,2.91.43l.64.12,3.37.47.39.07a7,7,0,0,1,3.61,1.1A4.77,4.77,0,0,1,73,32a4,4,0,0,1-.72,4l-.45.49c-1.23,1.13-2.42,2.31-3.6,3.5a1.48,1.48,0,0,0-.4.39c-1.24,1.16-2.45,2.35-3.64,3.55a1.27,1.27,0,0,0-.37.36c-1.27,1.19-2.51,2.4-3.74,3.63l-.2.2L59,49c-.06.06-.12.12-.1.22.17.92.34,1.83.5,2.75s.26,1.65.42,2.48L60.06,56c.22,1.31.41,2.62.69,3.92s.43,2.67.68,4l.64,4c0,.24,0,.48-.1.72a4,4,0,0,1-5.09,3,7.35,7.35,0,0,1-1-.43C54.56,70.5,53.23,69.8,51.87,69.13Z"
                                      transform="translate(-6.62 -8.15)"
                                    />
                                  </svg>
                                </>
                              ) : (
                                <>
                                  {ticket.assignedUser.username}
                                  <svg
                                    width="20"
                                    height="36"
                                    id="fas_fa-star"
                                    data-name="fas fa-star"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 66.62 63.69"
                                    className={
                                      "ticket-title-buttons nActiveFav"
                                    }
                                    style={{"marginLeft":"1rem"}}
                                  >
                                    <path
                                      className="cls-1"
                                      d="M51.87,69.13c-.78-.45-1.57-.86-2.38-1.25A16.65,16.65,0,0,0,47.87,67c-1.3-.71-2.61-1.41-3.93-2.06-.72-.41-1.45-.79-2.19-1.15s-1.19-.67-1.82-.94a14.72,14.72,0,0,0-1.76.92c-.77.36-1.52.75-2.25,1.18-1.33.65-2.63,1.35-3.93,2a15.78,15.78,0,0,0-1.47.77c-.89.44-1.77.89-2.63,1.39-1.32.65-2.63,1.33-3.92,2a11.05,11.05,0,0,1-1.23.52,4,4,0,0,1-4.54-2.17,3.68,3.68,0,0,1-.42-1.69c0-.28.07-.55.12-.82.17-1.06.35-2.12.53-3.18l.12-.61c.18-1.13.37-2.26.55-3.39.28-1.3.48-2.62.7-3.94a10,10,0,0,0,.25-1.74s.06-.07.07-.11c.12-.72.23-1.43.35-2.15.17-.9.33-1.81.5-2.72a.26.26,0,0,0-.1-.25L20,48.14l-.2-.2c-1.23-1.23-2.47-2.45-3.74-3.64a2.24,2.24,0,0,0-.44-.43c-1.16-1.19-2.37-2.33-3.57-3.48a1.44,1.44,0,0,0-.38-.36C10.46,38.84,9.27,37.65,8,36.51L7.56,36a4.09,4.09,0,0,1-.71-4,4.53,4.53,0,0,1,1.26-1.73,4.46,4.46,0,0,1,2.06-.82L12,29.18c1.34-.16,2.67-.36,4-.59,1.11-.12,2.2-.28,3.3-.48A1.78,1.78,0,0,0,20,28l1.18-.14L24,27.43c1.34-.16,2.68-.35,4-.58a.28.28,0,0,0,.32-.17c.4-.84.81-1.68,1.21-2.51.71-1.35,1.37-2.72,2-4.09A8.26,8.26,0,0,0,32.06,19c.53-.95,1-1.93,1.45-2.92l1.94-3.92L36,11a6.53,6.53,0,0,1,1.12-1.72A3.92,3.92,0,0,1,40.55,8.2a1.06,1.06,0,0,0,.25,0,4.12,4.12,0,0,1,2.34,1.55c.29.4.46.88.73,1.29a9.47,9.47,0,0,0,.54,1.11c.63,1.35,1.3,2.68,2,4,.45,1,1,2,1.46,3l.51,1,1.92,3.92c.42.85.84,1.7,1.25,2.56.07.14.16.23.33.2,1.34.23,2.67.42,4,.58.37.06.74.13,1.11.18l2.9.4a4.33,4.33,0,0,0,1,.15c1,.19,1.93.33,2.91.43l.64.12,3.37.47.39.07a7,7,0,0,1,3.61,1.1A4.77,4.77,0,0,1,73,32a4,4,0,0,1-.72,4l-.45.49c-1.23,1.13-2.42,2.31-3.6,3.5a1.48,1.48,0,0,0-.4.39c-1.24,1.16-2.45,2.35-3.64,3.55a1.27,1.27,0,0,0-.37.36c-1.27,1.19-2.51,2.4-3.74,3.63l-.2.2L59,49c-.06.06-.12.12-.1.22.17.92.34,1.83.5,2.75s.26,1.65.42,2.48L60.06,56c.22,1.31.41,2.62.69,3.92s.43,2.67.68,4l.64,4c0,.24,0,.48-.1.72a4,4,0,0,1-5.09,3,7.35,7.35,0,0,1-1-.43C54.56,70.5,53.23,69.8,51.87,69.13Z"
                                      transform="translate(-6.62 -8.15)"
                                    />
                                  </svg>
                                </>
                              )
                            ) : (
                              ticket.assignedUser
                            )
                          ) : (
                            "-"
                          )}
                        </td>
                        <td>{ticket.status}</td>
                        <td>
                          {this.props.ticketStore.transformDateToString(
                            ticket.date
                          )}
                        </td>
                        <td></td>
                        <td>{ticket.priority}</td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>
        </>
      );
    }
  }
}
export default TicketList;
