import React, { Component, Suspense } from "react";
import { Route } from "react-router-dom";
import { Router } from "react-router";
import { Provider, observer } from "mobx-react";
import SessionStore from "mobx-session";

import RootStore from "../stores/RootStore";
import PrivateRoute from "./PrivateRoute";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import ResetPassword from "./ResetPassword/ResetPassword";
import Login from "./Login";
import Initial from "./Initial/Initial";
import Navbar from "./Navbar";
import VerticalNavbar from "./VerticalNavbar";
import UserProfile from "./UserProfile/UserProfile";
import NewUser from "./NewUser/NewUser";
import TicketMain from "./Ticket/TicketMain";

import "../App.css";
import "../assets/css/custom.css";

SessionStore.initialize({ name: "frozen" });

@observer
class AppInitializer extends Component {
  render() {
    if (SessionStore.initialized) {
      const rootStore = new RootStore(SessionStore);
      return (
        <div className="App">
          <div>
            <Provider
              rootStore={rootStore}
              sessionStore={rootStore.sessionStore}
              navigationStore={rootStore.navigationStore}
              requestStore={rootStore.requestStore}
              userStore={rootStore.userStore}
              forgotPasswordStore={rootStore.forgotPasswordStore}
              resetPasswordStore={rootStore.resetPasswordStore}
              initialStore={rootStore.initialStore}
              userProfileStore={rootStore.userProfileStore}
              newUserStore={rootStore.newUserStore}
              ticketStore={rootStore.ticketStore}
            >
              <Router history={rootStore.navigationStore.history}>
                <Navbar />
                <VerticalNavbar />
                <div className="row" style={{"paddingLeft":"6%", "paddingTop":"5%"}}>
                  <PrivateRoute exact path="/initialPage" component={Initial} />
                  <Route path="/login" component={Login} />
                  <Route path="/forgotPassword" component={ForgotPassword} />
                  <Route path="/reset/:token" component={ResetPassword} />
                  <PrivateRoute path="/user/:id" component={UserProfile} />
                  <PrivateRoute exact path="/" component={Initial} />
                  <PrivateRoute path="/admin/newUser" component={NewUser} />
                  <PrivateRoute path="/ticket" component={TicketMain}/>
                </div>
              </Router>
            </Provider>
          </div>
        </div>
      );
    }
    return null;
  }
}

export default function App() {
  return (
    <Suspense fallback="loading">
      <AppInitializer />
    </Suspense>
  );
}
