import { observable, action, computed } from "mobx";
import i18n from "../../i18n";

class TicketListStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable loading = true;
  @observable list = true;
  @observable count = [];
  @observable tickets = [];
  user = null;
  @observable ticket = null;
  @observable visibleFilter = "";
  @observable tags = "";

  @observable displayModal = {
    similar: "display-none",
    subtasks: "display-none",
    activity: "display-none",
    links: "display-none",
    edit: "display-none",
    tags: "display-none",
    mail: "display-none",
  };

  @observable newTicket = {
    subject: "",
    SLAName: "",
    SLAStatus: "",
  };
  @observable mail = {
    subject: "",
    body: "",
    to: "",
  };
  @observable similarID = "";
  @observable linkText = "";
  @observable urlForTicket = "";
  @observable taskTitle = "";

  @observable progressOptions = {
    fromOption: "-1",
    toOption: "-1",
    value: "0",
    url: "",
    selectOptions: [
      { key: "01", value: "Waiting for support" },
      { key: "02", value: "In progress" },
      { key: "03", value: "Pending" },
      { key: "04", value: "Waiting for approval" },
      { key: "05", value: "Escalate" },
      { key: "06", value: "Canceled" },
      { key: "07", value: "Resolved" },
    ],
  };

  monthToName = (month) => {
    let answer = "";
    switch (month) {
      case "01":
        answer = i18n.t("months.january");
        break;
      case "02":
        answer = i18n.t("months.february");
        break;
      case "03":
        answer = i18n.t("months.march");
        break;
      case "04":
        answer = i18n.t("months.april");
        break;
      case "05":
        answer = i18n.t("months.may");
        break;
      case "06":
        answer = i18n.t("months.june");
        break;
      case "07":
        answer = i18n.t("months.july");
        break;
      case "08":
        answer = i18n.t("months.august");
        break;
      case "09":
        answer = i18n.t("months.september");
        break;
      case "10":
        answer = i18n.t("months.october");
        break;
      case "11":
        answer = i18n.t("months.november");
        break;
      case "12":
        answer = i18n.t("months.december");
        break;

      default:
        break;
    }
    return answer;
  };

  transformDateToString = (data) => {
    if (data === null || data === undefined || data === "" || data === "-") return "-";
    console.log(data)
    let string = data.split(".")[0].split("T");
    let date = string[0].split("-");
    let hour = string[1].split(":");
    let result =
      date[2] +
      " " +
      this.monthToName(date[1]) +
      " " +
      date[0] +
      " - " +
      hour[0] +
      ":" +
      hour[1];
    return result;
  };

  @action("Initial function") initializeStore = async () => {
    let user = await this.rootStore.sessionStore.getSession();
    console.log(user);
    this.user = user;
    this.count = (await this.rootStore.requestStore.countTickets(user.id)).data;
    this.loading = false;
    this.list = true;
  };

  @action("Clear Store") clearStore = () => {
    this.count = [];
    this.ticket = null;
  };

  @action("update object") updateObject = (object, attrName, value) => {
    object[attrName] = value;
  };

  @action("update attribute") updateProperty = (attrName, value) => {
    console.log("UpdateProperty Start", attrName, this[attrName], value);
    this[attrName] = value;
    console.log("UpdateProperty End", attrName, this[attrName], value);
  };

  @action("update nested attribute") updateNestedProperty = (
    attrPath,
    value
  ) => {
    console.log(attrPath, value);
    let path = attrPath.split(".");
    this[path[0]][path[1]] = value;
  };

  @action("update attribute") updateVisibleFilter = (value) => {
    console.log(value);
    if (this.visibleFilter === value) this.visibleFilter = "";
    else {
      this.visibleFilter = value;
    }
  };

  @action("get tickets for table") getTickets = async (type) => {
    console.log(type);
    this.tickets = (
      await this.rootStore.requestStore.getTickets(type, this.user.id)
    ).data;
    console.log(this.tickets);
  };

  @action("get ticket for ticket page") getTicket = async (ticket_ID) => {
    console.log(ticket_ID);
    this.ticket = (await this.rootStore.requestStore.getMail(ticket_ID)).data;
    console.log(this.ticket);
    if (this.ticket.status === null || this.ticket.status === "" || this.ticket.status === undefined)
      this.ticket.status = "pending";
  };

  @action("update progress status") updateProgress = async (text) => {
    let old = "";
    let type = "";
    let changed = "";
    if (text === "escalate") {
      type = "level";
      old = this.ticket.level;
      this.ticket.level = parseInt(this.ticket.level) + 1;
      changed = this.ticket.level;
    } else {
      type = "status";
      old = this.ticket.status;
      this.ticket.status = text;
      changed = this.ticket.status;
    }
    if (text === "closed" || text === "solved"){
      this.ticket.resolution = (new Date().toISOString()).date;
    }
    else this.ticket.resolution = "-"
    console.log(this.ticket, text);
    this.rootStore.requestStore.updateTicket(this.ticket);
    let user = await this.rootStore.sessionStore.getSession();
    this.count = (await this.rootStore.requestStore.countTickets(user.id)).data;
    this.createLog(this.ticket, type, old, changed);
    this.updateVisibleFilter("progress");
  };

  @action("Update priority of a ticket") updatePriority = async (text) => {
    let old = this.ticket.priority;
    this.ticket.priority = text;
    console.log(this.ticket, text);
    this.rootStore.requestStore.updateTicket(this.ticket);
    let user = await this.rootStore.sessionStore.getSession();
    this.createLog(this.ticket, "priority", old, text);
    this.updateVisibleFilter("priority");
  };

  @action("Toggle modal") toggleModal = (text) => {
    switch (text) {
      case "similar":
        if (this.displayModal.similar === "display-block")
          this.displayModal.similar = "display-none";
        else this.displayModal.similar = "display-block";
        break;
      case "subtasks":
        if (this.displayModal.subtasks === "display-block")
          this.displayModal.subtasks = "display-none";
        else this.displayModal.subtasks = "display-block";
        break;
      case "links":
        if (this.displayModal.links === "display-block")
          this.displayModal.links = "display-none";
        else this.displayModal.links = "display-block";
        break;
      case "edit":
        if (this.displayModal.edit === "display-block")
          this.displayModal.edit = "display-none";
        else this.displayModal.edit = "display-block";
        break;
      case "tags":
        if (this.displayModal.tags === "display-block")
          this.displayModal.tags = "display-none";
        else this.displayModal.tags = "display-block";
        break;
      case "mail":
        if (this.displayModal.mail === "display-block")
          this.displayModal.mail = "display-none";
        else this.displayModal.mail = "display-block";
        break;

      default:
        break;
    }
  };

  @action("Add similar issue to the list and upload to bbdd") addSimilar =
    async () => {
      let similarTicket = (
        await this.rootStore.requestStore.getMail(this.similarID)
      ).data;
      console.log(similarTicket, this.ticket);
      this.ticket.similar.push({
        _id: similarTicket._id,
        subject: similarTicket.subject,
      });
      console.log(this.ticket);
      this.rootStore.requestStore.updateTicket(this.ticket);
      similarTicket.similar.push({
        _id: this.ticket._id,
        subject: this.ticket.subject,
      });
      this.createLog(this.ticket, "similar", null, similarTicket._id);
      this.rootStore.requestStore.updateTicket(similarTicket);
      this.createLog(similarTicket, "similar", null, this.ticket._id);
    };

  @action("Add weblinks to the list and upload to bbdd") addWebLink =
    async () => {
      console.log(this.linkText, this.urlForTicket);
      this.ticket.weblinks.push({
        url: this.urlForTicket,
        description: this.linkText,
      });
      console.log(this.ticket);
      this.rootStore.requestStore.updateTicket(this.ticket);
      this.createLog(this.ticket, "weblink", null, this.urlForTicket);
    };

  @action("Add subtasks to the ticket") addSubtasks = async () => {
    console.log(this.ticket, this.taskTitle);
    this.ticket.tasks.push({
      _id: this.ticket.tasks.length,
      title: this.taskTitle,
      date: new Date().toISOString(),
      done: false,
    });
    console.log(this.ticket);
    this.rootStore.requestStore.updateTicket(this.ticket);
    this.createLog(this.ticket, "subtask", null, this.taskTitle);
  };

  @action("Update status of a subtask") updateStatusTask = async (
    name,
    id,
    checked
  ) => {
    console.log(name, id, checked);
    let pointer = id.split("-")[1];
    let old =
      this.ticket.tasks[pointer].title + " " + this.ticket.tasks[pointer].done;
    this.ticket.tasks[pointer].done = checked;
    this.rootStore.requestStore.updateTicket(this.ticket);
    this.createLog(
      this.ticket,
      "subtask",
      old,
      this.ticket.tasks[pointer].title + " " + checked
    );
  };

  @action("Assign the task") reassign = async () => {
    let user = await this.rootStore.sessionStore.getSession();
    if (
      this.ticket.assignedUser !== undefined &&
      this.ticket.assignedUser._id === user._id
    )
      return;
    let old = "None";
    if (this.ticket.assignedUser !== undefined)
      old = this.ticket.assignedUser.username;
    this.ticket.assignedUser = {
      _id: user._id,
      username: user.username,
    };
    this.rootStore.requestStore.updateTicket(this.ticket);
    this.createLog(this.ticket, "Assigned User", old, user.username);
  };

  @action("Edit ticket") editTicket = async () => {
    console.log(this.ticket, this.newTicket);
    let old1 = "";
    let old2 = "";
    let old3 = "";
    if (this.newTicket.subject !== "") {
      old1 = this.ticket.subject;
      this.ticket.subject = this.newTicket.subject;
    }
    if (this.newTicket.SLAName !== "") {
      old2 = this.ticket.sla.name;
      this.ticket.sla.name = this.newTicket.SLAName;
    }
    if (this.newTicket.SLAStatus !== "") {
      old3 = this.ticket.sla.status;
      this.ticket.sla.status = this.newTicket.SLAStatus;
    }
    this.rootStore.requestStore.updateTicket(this.ticket);
    if (this.newTicket.subject !== "") {
      this.createLog(this.ticket, "Medit", old1, this.newTicket.subject);
    }
    if (this.newTicket.SLAName !== "") {
      this.createLog(this.ticket, "Medit", old2, this.newTicket.SLAName);
    }
    if (this.newTicket.SLAStatus !== "") {
      this.createLog(this.ticket, "Medit", old3, this.newTicket.SLAStatus);
    }
  };

  @action("Create Log for activity ticket") createLog = async (
    ticket,
    type,
    old,
    changed
  ) => {
    console.log(old, changed);
    let user = await this.rootStore.sessionStore.getSession();
    let log = {
      date: new Date().toISOString(),
      type: type,
      user: user._id,
      username: user.username,
      original: old,
      change: changed,
    };
    console.log(log);
    console.log(ticket)
    ticket.activity.push(log);
    this.rootStore.requestStore.updateTicket(ticket);
  };

  @action("Add tags to the ticket") addTags = async () => {
    console.log(this.tags);
    let tags = [];
    /*    if (this.tags.includes(", ")) {
      tags = this.tags.split(", ");
    } else if (this.tags.includes(" ")) {
      tags = this.tags.split(" ");
    } else if (this.tags.includes(",")) {
      tags = this.tags.split(",");
    } else {
      tags.push(this.tags);
    }
*/
    let word = "";
    let inWord = true;
    for (let letter of this.tags) {
      console.log(letter, inWord);
      console.log(
        inWord &&
          (letter === "," || letter === " " || letter === "." || letter === "#")
      );
      if (
        inWord &&
        letter !== "," &&
        letter != " " &&
        letter !== "." &&
        letter !== "#"
      )
        word = word + letter;
      else if (
        inWord &&
        (letter === "," || letter === " " || letter === "." || letter === "#")
      ) {
        inWord = false;
        tags.push(word.toLowerCase());
        word = "";
      } else if (
        !inWord &&
        letter !== "," &&
        letter != " " &&
        letter !== "." &&
        letter !== "#"
      ) {
        inWord = true;
        word = word + letter;
      }
    }
    if (inWord) tags.push(word.toLowerCase());
    console.log(tags);
    for (let tag of tags) this.ticket.tags.push(tag);
    this.rootStore.requestStore.updateTicket(this.ticket);
    this.createLog(this.ticket, "tags", "Added", tags.toString());
  };

  @action("Change fav status")  toggleFav = async () => {
    console.log(this.ticket);

    let user = await this.rootStore.sessionStore.getSession();
    if (this.ticket.assignedUser._id !== undefined ||
      this.ticket.assignedUser._id !== user._id){
        this.reassign();
      }

    let old = this.ticket.assignedUser.favourite
    if (this.ticket.assignedUser.favourite) {
      this.ticket.assignedUser.favourite = false;
    } else {
      this.ticket.assignedUser.favourite = true;
    }

    this.rootStore.requestStore.updateTicket(this.ticket);
    this.createLog(this.ticket, "Favourite", old, this.ticket.assignedUser.favourite);
  };

  @action("Send Mail") sendMail = () => {
    console.log(this.mail);
    this.mail.to = this.ticket.sender;
    this.rootStore.requestStore.sendMail(this.mail);
  };
}

export default TicketListStore;
