import UserStore from "./UserStore";
import NavigationStore from "./NavigationStore";
import RequestStore from "./RequestStore";
import InitialStore from "../components/Initial/InitialStore"
import ForgotPasswordStore from "../components/ForgotPassword/ForgotPasswordStore"
import ResetPasswordStore from "../components/ResetPassword/ResetPasswordStore";
import PageStore from "./PageStore"
import i18n from "../i18n"
import UserProfileStore from "../components/UserProfile/UserProfileStore";
import NewUserStore from "../components/NewUser/NewUserStore";
import TicketStore from "../components/Ticket/TicketStore"

class RootStore {
  constructor(sessionStore) {
    this.sessionStore = sessionStore;
    this.navigationStore = new NavigationStore(this);
    this.requestStore = new RequestStore(this);
    this.userStore = new UserStore(this);
    this.initialStore = new InitialStore(this);
    this.forgotPasswordStore = new ForgotPasswordStore(this);
    this.resetPasswordStore = new ResetPasswordStore(this);
    this.pageStore = new PageStore(this);
    this.userProfileStore = new UserProfileStore(this);
    this.newUserStore = new NewUserStore(this);
    this.ticketStore = new TicketStore(this)
    this.t = i18n.t;
    this.version = "0.0.1"
  }

  getVersion = () => {
    return this.version;
  };
}

export default RootStore;
