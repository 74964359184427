import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import MyButton from "../basicUI/MyButton";
import { withTranslation } from "react-i18next";
import MyInput from "../basicUI/MyInput";
/* import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
 */
@inject("newUserStore", "sessionStore", "navigationStore")
@observer
class NewUser extends Component {
  componentDidMount() {
    try {      
      let { t } = this.props;
      document.title = t("newUser.title");
      this.props.newUserStore.initializeStore();
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  }

  componentWillUnmount() {}

  isInMyRegions = (region) => {
    let ret = false;
    this.props.newUserStore.myRegions.forEach((myRegion) => {
      if (myRegion._id === region._id) {
        ret = true;
      }
    });
    return ret;
  };

  render() {
    const { t } = this.props;
    if (this.props.sessionStore.initialized) {
      return (
        <>
          {this.props.newUserStore.initialized ? (
            <main role="main" className="container-fluid newUser-page">
              <div
                className={
                  "alert alert-danger" +
                  (!!this.props.newUserStore.showAlert
                    ? " alert-custom show"
                    : " alert-custom hide")
                }
              >
                <p id="danger-alert-newUser"></p>
                <button
                  type="button"
                  className="close"
                  onClick={() => {
                    this.props.newUserStore.updateProperty("showAlert", false);
                  }}
                >
                  x
                </button>
              </div>
              <div
                className={
                  "alert alert-success" +
                  (!!this.props.newUserStore.confirmAlert
                    ? " alert-custom show"
                    : " alert-custom hide")
                }
              >
                <p id="succes-alert-newUser"></p>
                <button
                  type="button"
                  className="close"
                  onClick={() => {
                    this.props.newUserStore.updateProperty(
                      "confirmAlert",
                      false
                    );
                  }}
                >
                  {" "}
                  x{" "}
                </button>
              </div>
              <div className="row newUser">
                <div className="col-md-3">
                  <h5 style={{ width: "100%" }}>{t("navBar.newUser")}</h5>
                  <div className="inputs" style={{ width: "49%" }}>
                    <MyInput
                      id="firstName"
                      visible="false"
                      label="Name"
                      className="input-newUser"
                      name="firstName"
                      value={this.props.newUserStore.newUserForm.firstName}
                      onChange={(key, value) => {
                        this.props.newUserStore.updateFormProperty(key, value);
                      }}
                      placeholder={t("newUser.placeholders.name")}
                    ></MyInput>
                  </div>
                  <div className="inputs" style={{ width: "2%" }}></div>
                  <div className="inputs" style={{ width: "49%" }}>
                    <MyInput
                      id="lastName"
                      visible="false"
                      label="LastName"
                      className="input-newUser"
                      name="lastName"
                      value={this.props.newUserStore.newUserForm.lastName}
                      onChange={(key, value) => {
                        this.props.newUserStore.updateFormProperty(key, value);
                      }}
                      placeholder={t("newUser.placeholders.lastName")}
                    ></MyInput>
                  </div>

                  <div className="inputs" style={{ width: "100%" }}>
                    <MyInput
                      id="email"
                      visible="false"
                      label="Email"
                      className="input-newUser"
                      name="email"
                      value={this.props.newUserStore.newUserForm.email}
                      onChange={(key, value) => {
                        this.props.newUserStore.updateFormProperty(key, value);
                      }}
                      placeholder={t("newUser.placeholders.email")}
                    ></MyInput>
                  </div>
                  <div className="inputs" style={{ width: "100%" }}>
                    <MyInput
                      id="username"
                      visible="false"
                      label="username"
                      className="input-newUser"
                      name="username"
                      value={this.props.newUserStore.newUserForm.username}
                      onChange={(key, value) => {
                        this.props.newUserStore.updateFormProperty(key, value);
                      }}
                      placeholder={t("newUser.placeholders.username")}
                    ></MyInput>
                  </div>

                  <div className="inputs" style={{ width: "100%" }}>
                    <MyInput
                      type="password"
                      visible="false"
                      label={t("newUser.passwordModal.password")}
                      className="password-input-newUser"
                      name="password"
                      placeholder={t("newUser.passwordModal.newPassword")}
                      onChange={this.props.newUserStore.updatePasswordForm}
                      value={this.props.newUserStore.passwordObject.password}
                    />
                  </div>
                  <div className="inputs" style={{ width: "100%" }}>
                    <MyInput
                      type="password"
                      visible="false"
                      label={t("newUser.passwordModal.repeat")}
                      className="password-input-newUser"
                      name="passwordRepeat"
                      placeholder={t("newUser.passwordModal.repeat")}
                      onChange={this.props.newUserStore.updatePasswordForm}
                      value={
                        this.props.newUserStore.passwordObject.passwordRepeat
                      }
                    />
                  </div>

                  <hr style={{ marginTop: "0" }} />

                  <div
                    id="password"
                    className="change-pass-indications-newUser"
                  >
                    <p id="letter">
                      {t("newUser.passwordModal.letter")}
                      <b
                        style={
                          this.props.newUserStore.hasLetter
                            ? { color: "green" }
                            : { color: "red" }
                        }
                      >
                        {t("newUser.passwordModal.lowercase")}
                      </b>
                    </p>
                    <p id="capital">
                      {t("newUser.passwordModal.letter")}
                      <b
                        style={
                          this.props.newUserStore.hasCapital
                            ? { color: "green" }
                            : { color: "red" }
                        }
                      >
                        {t("newUser.passwordModal.uppercase")}
                      </b>
                    </p>
                    <p id="number">
                      {t("newUser.passwordModal.a")}
                      <b
                        style={
                          this.props.newUserStore.hasNumber
                            ? { color: "green" }
                            : { color: "red" }
                        }
                      >
                        {t("newUser.passwordModal.number")}
                      </b>
                    </p>
                    <p id="specialChar">
                      {t("newUser.passwordModal.a")}
                      <b
                        style={
                          this.props.newUserStore.hasSpecialChar
                            ? { color: "green" }
                            : { color: "red" }
                        }
                      >
                        {t("newUser.passwordModal.special")}
                      </b>
                    </p>
                    <p id="length">
                      {t("newUser.passwordModal.minimum")}{" "}
                      <b
                        style={
                          this.props.newUserStore.hasLength
                            ? { color: "green" }
                            : { color: "red" }
                        }
                      >
                        {t("newUser.passwordModal.eight")}
                      </b>
                    </p>
                    <p id="passwordsMatch">
                      {t("newUser.passwordModal.both")}
                      <b
                        style={
                          this.props.newUserStore.passwordsMatch
                            ? { color: "green" }
                            : { color: "red" }
                        }
                      >
                        {t("newUser.passwordModal.equal")}
                      </b>
                    </p>
                  </div>

                  <div className="supervisor">
                    <p>Is Supervisor</p>
                    <div className="input">
                      <input id="supervisorCheckbox" type="checkbox"></input>
                    </div>
                  </div>

                  <div className="submit-newUser">
                    <MyButton
                      id="btn-submit-newUser"
                      type="button"
                      className="btn btn-info btn-submit-newUser"
                      onClick={() => {
                        this.props.newUserStore.register();
                      }}
                      text={t("newUser.submit")}
                    ></MyButton>
                  </div>
                </div>

                <div className="col-md-1"></div>
              </div>
            </main>
          ) : null}
        </>
      );
    }
  }
}
export default withTranslation()(NewUser);
