import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import MyButton from "../basicUI/MyButton";
import MyCheckboxList from "../basicUI/MyCheckboxList";
import MySelect from "../basicUI/MySelect";

@inject("initialStore", "sessionStore", "navigationStore", "userStore")
@observer
class InitialTable extends Component {
  async componentDidMount() {
    try {
      if (this.props.sessionStore.initialized) {
        let { t } = this.props;
        document.title = "Initial Page";
        this.props.initialStore.initializeStore();
        this.props.navigationStore.replace("ticket")
      }
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  }

  componentWillUnmount() {
    try {
      this.props.initialStore.clearStore();
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  }

  render() {
    const { t } = this.props;
    if (this.props.sessionStore.initialized) {
      if(this.props.initialStore.mails !== []){
        return (
          this.props.initialStore.mails.map((mail, i) => {
            return (
              <p>{mail.subject}</p>
            );
          })
        )
      }
      else return (
        <>
        </>
      )
    }
  }
}
export default withTranslation()(InitialTable);
