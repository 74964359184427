import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import "../App.css";
import { observer, inject } from "mobx-react";

import logo from "../assets/icons/logo_AV.svg";

@inject("userStore", "rootStore")
@observer
class Navbar extends Component {
  // PER CONTROLAR EL LOGIN I LOGOUT DES D'UN ALTRE TAB DEL NAVEGADOR
  componentDidMount() {
    window.localStorage.setItem(
      "REQUESTING_SHARED_CREDENTIALS",
      Date.now().toString()
    );
    window.localStorage.removeItem("REQUESTING_SHARED_CREDENTIALS");
  }

  render() {
    const { t } = this.props;
    /**  TODO RECUPERAR L'ID DE LA SESSIONSTORE**/
    let user = this.props.userStore.getUser();
    let id;
    if (user === null) return null;
    else {
      id = user.id;
      return (
        <div className="navBar">
          <div>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
              <NavLink
                className="navbar-brand"
                to="/initialPage"
                onClick={() => {
                  if (
                    this.props.rootStore.navigationStore.history.location
                      .pathname === "/initialPage"
                  )
                    this.props.rootStore.navigationStore.reload();
                  else
                    this.props.rootStore.navigationStore.push("/initialPage");
                }}
              >
                <img src={logo} width="50" alt="" />
              </NavLink>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarToggler"
                aria-controls="navbarToggler"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarToggler">
                <div className="col-md-3" style={{}}>
                  <ul
                    className="navbar-nav nav-fill nav-right"
                    id="nav-secondary"
                  ></ul>
                </div>
                <div className="huha col-md-4"></div>

                <div className="col-md-5">
                  <ul
                    className="navbar-nav nav-fill nav-right"
                    id="nav-secondary"
                  >
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        activeClassName="active"
                        to={`/user/${id}`}
                      >
                        {t("navBar.profile")}
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        activeClassName="active"
                        to={`/admin/newUser`}
                      >
                        {t("navBar.newUser")}
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/"
                        className="nav-link"
                        onClick={this.props.userStore.logout}
                      >
                        {"Logout"}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      );
    }
  }
}

export default withTranslation()(Navbar);
