import { observable, action, computed } from "mobx";
import i18n from "../../i18n";

class NewUserStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable regions;
  assignedRegions = [];
  @observable myRegions;
  @observable initialized = false;
  @observable regionSearch = "";
  @observable showAlert = false;
  @observable confirmAlert = false;

  lowerCaseLetters = /[a-z]/g;
  upperCaseLetters = /[A-Z]/g;
  numbers = /[0-9]/g;
  specialChars = /[^\w\s]/gi;
  requiredLength = 8;

  @observable newUserForm = {
    firstName: "",
    lastName: "",
    email: "",
    username: "",
  };

  @observable passwordObject = {
    passwordRepeat: "",
    password: "",
  };

  @action("Initialize Store") initializeStore = async () => {
    this.initialized = true;
  };

  @action("Toggle the alert") toggleBadAlert = () => {
    this.updateProperty("showAlert", true);
    document.getElementById("danger-alert-newUser").innerHTML =
      i18n.t("newUser.alertData") + "<br>";

    if (!this.newUserForm.firstName.length > 0)
      document.getElementById("danger-alert-newUser").innerHTML +=
        i18n.t("newUser.nameMissing") + "<br>";
    if (!this.newUserForm.lastName.length > 0)
      document.getElementById("danger-alert-newUser").innerHTML +=
        i18n.t("newUser.surnameMissing") + "<br>";
    if (!this.getEmailIsValid)
      document.getElementById("danger-alert-newUser").innerHTML +=
        i18n.t("newUser.invalidEmail") + "<br>";
    if (!this.newUserForm.username.length > 0)
      document.getElementById("danger-alert-newUser").innerHTML +=
        i18n.t("newUser.usernameMissing") + "<br>";
    if (document.getElementById("selectGroup").value === "true")
      document.getElementById("danger-alert-newUser").innerHTML +=
        i18n.t("newUser.groupMissing") + "<br>";

    if (!this.hasLetter)
      document.getElementById("danger-alert-newUser").innerHTML +=
        i18n.t("newUser.lowercaseMissing") + "<br>";
    if (!this.hasCapital)
      document.getElementById("danger-alert-newUser").innerHTML +=
        i18n.t("newUser.uppercaseMissing") + "<br>";
    if (!this.hasNumber)
      document.getElementById("danger-alert-newUser").innerHTML +=
        i18n.t("newUser.numberMissing") + "<br>";
    if (!this.hasSpecialChar)
      document.getElementById("danger-alert-newUser").innerHTML +=
        i18n.t("newUser.specialMissing") + "<br>";
    if (!this.hasLength)
      document.getElementById("danger-alert-newUser").innerHTML +=
        i18n.t("newUser.passwordLength") + "<br>";
    if (!this.passwordsMatch)
      document.getElementById("danger-alert-newUser").innerHTML +=
        i18n.t("newUser.equalPassword") + "<br>";
    setTimeout(() => {
      this.updateProperty("showAlert", false);
    }, 5000);
  };

  @action("Toggle the alert") toggleErrorAlert = (errorCode) => {
    this.updateProperty("showAlert", true);
    if (errorCode === "011000") {
      document.getElementById("danger-alert-newUser").innerHTML =
        i18n.t("newUser.alertData") + "<br>";
      document.getElementById("danger-alert-newUser").innerHTML +=
        i18n.t("newUser.emailTaken") + "<br>";
    }
    if (errorCode === "111000") {
      document.getElementById("danger-alert-newUser").innerHTML =
        i18n.t("newUser.alertData") + "<br>";
      document.getElementById("danger-alert-newUser").innerHTML +=
        i18n.t("newUser.userTaken") + "<br>";
    } else
      document.getElementById("danger-alert-newUser").innerHTML =
        i18n.t("newUser.alertError") + "<br>";

    setTimeout(() => {
      this.updateProperty("showAlert", false);
    }, 5000);
  };

  @action("Toggle the alert") toggleGoodAlert = () => {
    this.updateProperty("confirmAlert", true);
    document.getElementById("succes-alert-newUser").innerHTML = i18n.t(
      "newUser.alertConfirm"
    );
    setTimeout(() => {
      this.updateProperty("confirmAlert", false);
    }, 5000);
  };

  @action("update property not in form") updateProperty(key, value) {
    this[key] = value;
  }

  @action("update form property") updateFormProperty(key, value) {
    this.newUserForm[key] = value;
  }

  @action("update password values") updatePasswordForm = (key, value) => {
    this.passwordObject[key] = value;
  };

  @computed get hasLetter() {
    return !!this.passwordObject.password.match(this.lowerCaseLetters);
  }
  @computed get hasCapital() {
    return !!this.passwordObject.password.match(this.upperCaseLetters);
  }
  @computed get hasNumber() {
    return !!this.passwordObject.password.match(this.numbers);
  }
  @computed get hasSpecialChar() {
    return (
      !!this.passwordObject.password.match(this.specialChars) ||
      this.passwordObject.password.match("_")
    );
  }
  @computed get hasLength() {
    return this.passwordObject.password.length >= this.requiredLength;
  }
  @computed get passwordsMatch() {
    return (
      this.passwordObject.password.length > 0 &&
      this.passwordObject.password === this.passwordObject.passwordRepeat
    );
  }

  @computed get getPasswordFormIsValid() {
    return (
      this.hasCapital &&
      this.hasLength &&
      this.hasLetter &&
      this.hasNumber &&
      this.hasSpecialChar &&
      this.passwordsMatch
    );
  }

  @computed get getEmailIsValid() {
    let emailSplitted = this.newUserForm.email.split("@");
    console.log(emailSplitted)
    if (emailSplitted.length !== 2) return false;
    let domain = emailSplitted[1].split(".");
    
    if (domain.length === 3 && domain[1] === "gov" && domain[2]=== "uk") {
      return (
        domain.length === 3 &&
        emailSplitted[0].length > 0 &&
        domain[0].length > 0 &&
        domain[1].length > 0 &&
        domain[2].length > 0
      );      
  } else {
     
      return (
        domain.length === 2 &&
        emailSplitted[0].length > 0 &&
        domain[0].length > 0 &&
        domain[1].length > 0
      );
    }
  }

  @computed get getUserIsValid() {
    return (
      this.newUserForm.firstName.length > 0 &&
      this.newUserForm.lastName.length > 0 &&
      this.getEmailIsValid &&
      this.newUserForm.username.length > 0
    );
  }

  @computed get getDataIsValid() {
    return this.getPasswordFormIsValid && this.getUserIsValid;
  }

  @action("Register new User") register = async () => {
    //console.log("entering Register in NU Store")
    if (this.getDataIsValid) {
      let body = {
        email: this.newUserForm.email,
        username: this.newUserForm.username,
        isSuperuser: false,
        isSupervisor: document.getElementById("supervisorCheckbox").checked,
        password: this.passwordObject.password,
        firstName: this.newUserForm.firstName,
        lastName: this.newUserForm.lastName,
        regions: this.assignedRegions,
      };
      console.log("calling requestStore");
      
      let res = await this.rootStore.requestStore.register(body);
      console.log(res)
      if (res !== undefined && res.statusText === "OK" && !res.data.error) {
        this.toggleGoodAlert();
        this.newUserForm = {
          firstName: "",
          lastName: "",
          email: "",
          username: "",
        };
        this.passwordObject = {
          passwordRepeat: "",
          password: "",
        };
      } else {
        console.log(res);
        this.toggleErrorAlert(res.data);
      }
    } else {
      this.toggleBadAlert();
      console.log("The data you are entering is not valid");
    }
    //console.log("exiting Register in NU Store")
  };
}
export default NewUserStore;
