import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import "../App.css";
import { observer, inject } from "mobx-react";

import logo from "../assets/icons/logo_AV.svg";
import speed from "../assets/icons/speed_FILL0_wght400_GRAD0_opsz48.png"
import ticket from "../assets/icons/local_activity_FILL0_wght400_GRAD0_opsz48.png"
import book from "../assets/icons/menu_book_FILL0_wght400_GRAD0_opsz48.png"
import chart from "../assets/icons/bar_chart_FILL0_wght400_GRAD0_opsz48.png"
import gear from "../assets/icons/settings_FILL0_wght400_GRAD0_opsz48.png"

@inject("userStore", "rootStore")
@observer
class Navbar extends Component {
  // PER CONTROLAR EL LOGIN I LOGOUT DES D'UN ALTRE TAB DEL NAVEGADOR
  componentDidMount() {
    window.localStorage.setItem(
      "REQUESTING_SHARED_CREDENTIALS",
      Date.now().toString()
    );
    window.localStorage.removeItem("REQUESTING_SHARED_CREDENTIALS");
  }

  render() {
    const { t } = this.props;
    /**  TODO RECUPERAR L'ID DE LA SESSIONSTORE**/
    let user = this.props.userStore.getUser();
    let id;
    if (user === null) return null;
    else {
      id = user.id;
      return (
        <div className="verticalNavbar">
          <ul>
            <li>
              <nav>
                <NavLink
                  className="verticalNavbar-brand"
                  to="/initialPage"
                  onClick={() => {
                    if (
                      this.props.rootStore.navigationStore.history.location
                        .pathname === "/initialPage"
                    )
                      this.props.rootStore.navigationStore.reload();
                    else
                      this.props.rootStore.navigationStore.push("/initialPage");
                  }}
                >
                  <img src={speed} width="50" alt="" />
                </NavLink>
              </nav>
            </li>
            <li>
            <nav>
                <NavLink
                  className="verticalNavbar-brand"
                  to="/ticket"
                  onClick={() => {
                    if (
                      this.props.rootStore.navigationStore.history.location
                        .pathname === "/ticket"
                    )
                      this.props.rootStore.navigationStore.reload();
                    else
                      this.props.rootStore.navigationStore.push("/ticket");
                  }}
                >
                  <img src={ticket} width="50" alt="" />
                </NavLink>
              </nav>
            </li>
            <li>
            <nav>
                <NavLink
                  className="verticalNavbar-brand"
                  to="/initialPage"
                  onClick={() => {
                    if (
                      this.props.rootStore.navigationStore.history.location
                        .pathname === "/initialPage"
                    )
                      this.props.rootStore.navigationStore.reload();
                    else
                      this.props.rootStore.navigationStore.push("/initialPage");
                  }}
                >
                  <img src={book} width="50" alt="" />
                </NavLink>
              </nav>
            </li>
            <li>
            <nav>
                <NavLink
                  className="verticalNavbar-brand"
                  to="/initialPage"
                  onClick={() => {
                    if (
                      this.props.rootStore.navigationStore.history.location
                        .pathname === "/initialPage"
                    )
                      this.props.rootStore.navigationStore.reload();
                    else
                      this.props.rootStore.navigationStore.push("/initialPage");
                  }}
                >
                  <img src={chart} width="50" alt="" />
                </NavLink>
              </nav>
            </li>
            <li>
            <nav>
                <NavLink
                  className="verticalNavbar-brand"
                  to="/initialPage"
                  onClick={() => {
                    if (
                      this.props.rootStore.navigationStore.history.location
                        .pathname === "/initialPage"
                    )
                      this.props.rootStore.navigationStore.reload();
                    else
                      this.props.rootStore.navigationStore.push("/initialPage");
                  }}
                >
                  <img src={gear} width="50" alt="" />
                </NavLink>
              </nav>
            </li>
          </ul>
        </div>
      );
    }
  }
}

export default withTranslation()(Navbar);
