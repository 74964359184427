const axios = require("axios");

class RequestStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  logoutIfSessionHasExpired = async (data) => {
    if (data === "auth error") {
      await this.rootStore.userStore.logout();
      throw new Error("AuthError");
    }
  };

  forgotPassword = async (username, groupCode) => {
    try {
      let res = await axios.post("/api/session/forgotPassword", {
        username,
        groupCode,
      });
      return res;
    } catch (error) {
      console.log("An error ocurred while executing the action requested");
    }
  };

  updatePasswordViaEmail = async (
    username,
    group,
    password,
    resetPasswordToken
  ) => {
    try {
      let res = await axios.put("/api/session/updatePasswordViaEmail", {
        username,
        group,
        password,
        resetPasswordToken,
      });
      return res;
    } catch (error) {
      console.log("An error ocurred while executing the action requested");
    }
  };

  resetPassword = async (resetPasswordToken, groupCode) => {
    try {
      const res = await axios.get("/api/session/resetPassword", {
        params: {
          resetPasswordToken,
          groupCode,
        },
      });
      return res;
    } catch (error) {
      console.log("An error ocurred while executing the action requested");
    }
  };

  loginUser = async (user) => {
    try {
      console.log("Trying to log in");
      let res = await axios.post("/api/session/login", {
        username: user.username,
        password: user.password,
      });
      await this.logoutIfSessionHasExpired(res.data);
      return res;
    } catch (error) {
      console.log(error);
      console.log("An error ocurred while executing the action requested");
      return "err";
    }
  };

  logoutUser = async () => {
    try {
      await axios.get("/api/session/logout");
    } catch (error) {
      console.log("An error ocurred while executing the action requested");
    }
  };

  getUserById = async (id, params) => {
    try {
      let res = await axios.get("/api/user/" + id, { params: params });
      await this.logoutIfSessionHasExpired(res.data);
      return res;
    } catch (error) {
      console.log("An error ocurred while executing the action requested");
    }
  };

  register = async (body) => {
    try {
      //console.log("entering Register in Request Store")
      let res = await axios.get("/api/session/registerFromPage", {
        params: body,
      });

      await this.logoutIfSessionHasExpired(res.data);

      console.log("res =", res);
      //console.log("exiting Register in Request Store")
      return res;
    } catch (err) {
      console.log("An error ocurred while executing the action requested");
    }
  };

  getMail = async (id) => {
    try {
      let res = await axios.get("/api/mail/" + id, {});
      return res;
    } catch (error) {
      console.log("An error ocurred while executing the action requested");
    }
  };

  getAllMails = async () => {
    try {
      let res = await axios.get("api/mail/", {});
      return res;
    } catch (error) {
      console.log(error);
      console.log("An error ocurred while executing the action requested");
    }
  };

  countTickets = async (user) => {
    try {
      let res = await axios.get("api/mail/count?user=" + user, {});
      return res;
    } catch (error) {
      console.log(error);
      console.log("An error ocurred while executing the action requested");
    }
  };

  getTickets = async (type, user) => {
    try {
      let res = await axios.get(
        "api/mail/tickets?type=" + type + "&user=" + user,
        {}
      );
      return res;
    } catch (error) {
      console.log(error);
      console.log("An error ocurred while executing the action requested");
    }
  };

  updateTicket = async (ticket) => {
    try {
      console.log(ticket);
      let res = await axios.put("api/mail/updateTicket", { ticket: ticket });
      return res;
    } catch (error) {
      console.log(error);
      console.log("An error ocurred while executing the action requested");
    }
  };

  sendMail = async (mail) => {
    try {
      console.log(mail);
      let res = axios.post("api/mail/sendTicketMail", { mail });
      return res;
    } catch (error) {
      console.log(error);
      console.log("An error ocurred while executing the action requested");
    }
  };
}
export default RequestStore;
