import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import Ticket from "./Ticket";
import TicketList from "./TicketList";
import { toJS } from "mobx";

@inject("ticketStore", "sessionStore", "navigationStore", "userStore")
@observer
class TicketMain extends Component {
  async componentDidMount() {
    try {
      if (this.props.sessionStore.initialized) {
        let { t } = this.props;
        this.props.ticketStore.initializeStore();
      }
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  }

  componentWillUnmount() {
    try {
      this.props.ticketStore.clearStore();
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  }

  render() {
    const { t } = this.props;
    console.log(this.props.ticketStore.count);
    if (this.props.ticketStore.loading === false) {
      return (
        <div>
          <div
            className="row"
            style={{ marginRight: "0px", marginLeft: "0px", gap: "2rem", "marginTop":"2rem"  }}
          >
            <div
              className="top-ticket-btn"
              onClick={() => {
                this.props.ticketStore.getTickets("open");
                this.props.ticketStore.list = true;
              }}
            >
              <div className="top-ticket-text">All Open</div>
              <div className="top-ticket-number">
                <p>{this.props.ticketStore.count[1]}</p>
              </div>
            </div>
            <div
              className="top-ticket-btn"
              onClick={() => {
                this.props.ticketStore.getTickets("assigned");
                this.props.ticketStore.list = true;
              }}
            >
              <div className="top-ticket-text">Assigned to me</div>
              <div className="top-ticket-number">
                {this.props.ticketStore.count[2]}
              </div>
            </div>
            <div
              className="top-ticket-btn"
              onClick={() => {
                this.props.ticketStore.getTickets("noAssigned");
                this.props.ticketStore.list = true;
              }}
            >
              <div className="top-ticket-text">Unassigned Issues</div>
              <div className="top-ticket-number">
                {this.props.ticketStore.count[3]}
              </div>
            </div>
            <div
              className="top-ticket-btn"
              onClick={() => {
                this.props.ticketStore.getTickets("scalated");
                this.props.ticketStore.list = true;
              }}
            >
              <div className="top-ticket-text">Escalated Issues</div>
              <div className="top-ticket-number">
                {this.props.ticketStore.count[4]}
              </div>
            </div>
            <div
              className="top-ticket-btn"
              onClick={() => {
                this.props.ticketStore.getTickets("solved");
                this.props.ticketStore.list = true;
              }}
            >
              <div className="top-ticket-text">Solved</div>
              <div className="top-ticket-number">
                {this.props.ticketStore.count[5]}
              </div>
            </div>
            <div
              className="top-ticket-btn"
              onClick={() => {
                this.props.ticketStore.getTickets("closed");
                this.props.ticketStore.list = true;
              }}
            >
              <div className="top-ticket-text">Closed</div>
              <div className="top-ticket-number">
                {this.props.ticketStore.count[6]}
              </div>
            </div>
          </div>

          {this.props.ticketStore.list ? <TicketList /> : <Ticket />}
        </div>
      );
    } else {
      return <></>;
    }
  }
}
export default TicketMain;
