import { observable, action, computed } from "mobx";

class InitialStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }
  
  @observable mail = null;
  @observable mails = [];

  @action("Initial function") initializeStore = async () => {
    this.getAllMails()
  };

  @action("Clear Store") clearStore = () => {
  };

  @action("update object") updateObject = (object, attrName, value) => {
    object[attrName] = value;
  };

  @action("update attribute") updateProperty = (attrName, value) => {
    //console.log("UpdateProperty Start",attrName,this[attrName],value)
    this[attrName] = value;
    //console.log("UpdateProperty End",attrName,this[attrName],value)
  };

  @action("update nested attribute") updateNestedProperty = (
    attrPath,
    value
  ) => {
    let path = attrPath.split(".");
    this[path[0]][path[1]] = value;
  };

  @action("get All Mails") getAllMails = async () => {
    this.mails = (await this.rootStore.requestStore.getAllMails()).data;
    console.log(this.mails);
  }

}

export default InitialStore;
