import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import MyButton from "../basicUI/MyButton";
import Robot from "./Robot";
import MyInput from "../basicUI/MyInput";
import MyCheckbox from "../basicUI/MyCheckbox";

@inject("ticketStore", "sessionStore", "navigationStore", "userStore")
@observer
class Ticket extends Component {
  async componentDidMount() {
    try {
      if (this.props.sessionStore.initialized) {
        let { t } = this.props;
        document.title = "Ticket";
      }
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  }

  componentWillUnmount() {
    try {
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  }

  render() {
    const { t } = this.props;
    if (this.props.ticketStore.ticket !== null) {
      let title;
      this.props.ticketStore.ticket.subject.length >= 75
        ? (title =
            this.props.ticketStore.ticket.subject.substring(0, 75) + "...")
        : (title = this.props.ticketStore.ticket.subject);
      return (
        <div style={{ display: "flex" }}>
          <div className="modalTicket display-block">
            <div
              id="modal-div-2"
              className={this.props.ticketStore.displayModal.edit}
            >
              <section className="modal-main">
                <div className="card container">
                  <form className="justify-content-center">
                    <p className="invalid" style={{ marginTop: "1rem" }}>
                      {"Edit Ticket"}
                    </p>
                    <div style={{ gap: "1rem", display: "flex" }}>
                      <p style={{ marginBottom: "0rem", minWidth: "5rem" }}>
                        Title
                      </p>
                      <MyInput
                        visible={true}
                        name="newTicket.subject"
                        placeholder={this.props.ticketStore.ticket.subject}
                        value={this.props.ticketStore.newTicket.subject}
                        onChange={this.props.ticketStore.updateNestedProperty}
                      />
                    </div>
                    <hr />
                    <div className="modal-btn-tickets">
                      <MyButton
                        className="btn cancel-btn"
                        text="Cancel"
                        type="button"
                        onClick={() => {
                          this.props.ticketStore.toggleModal("edit");
                        }}
                      />
                      <MyButton
                        className="btn accept-btn"
                        text="Add"
                        type="button"
                        onClick={() => {
                          this.props.ticketStore.editTicket();
                          this.props.ticketStore.toggleModal("edit");
                        }}
                      />
                    </div>
                  </form>
                </div>
              </section>
            </div>
            <div
              id="modal-div-2"
              className={this.props.ticketStore.displayModal.similar}
            >
              <section className="modal-main">
                <div className="card container">
                  <form className="justify-content-center">
                    <p className="invalid" style={{ marginTop: "1rem" }}>
                      {"Write the id of the issue that you want to add"}
                    </p>
                    <MyInput
                      visible={true}
                      name="similarID"
                      placeholder={"id of the issue"}
                      value={this.props.ticketStore.similarID}
                      onChange={this.props.ticketStore.updateProperty}
                    />
                    <hr />
                    <div className="modal-btn-tickets">
                      <MyButton
                        className="btn cancel-btn"
                        text="Cancel"
                        type="button"
                        onClick={() => {
                          this.props.ticketStore.toggleModal("similar");
                          this.props.ticketStore.updateProperty(
                            "addSimilar",
                            ""
                          );
                        }}
                      />
                      <MyButton
                        className="btn accept-btn"
                        text="Add"
                        type="button"
                        onClick={() => {
                          this.props.ticketStore.addSimilar();
                          this.props.ticketStore.toggleModal("similar");
                          this.props.ticketStore.updateProperty(
                            "addSimilar",
                            ""
                          );
                        }}
                      />
                    </div>
                  </form>
                </div>
              </section>
            </div>
            <div
              id="modal-div-2"
              className={this.props.ticketStore.displayModal.links}
            >
              <section className="modal-main">
                <div className="card container">
                  <form className="justify-content-center">
                    <p className="invalid" style={{ marginTop: "1rem" }}>
                      {"Write the url"}
                    </p>
                    <MyInput
                      visible={true}
                      name="urlForTicket"
                      placeholder={"URL"}
                      value={this.props.ticketStore.urlForTicket}
                      onChange={this.props.ticketStore.updateProperty}
                    />
                    <MyInput
                      visible={true}
                      name="linkText"
                      placeholder={"Description"}
                      value={this.props.ticketStore.linkText}
                      onChange={this.props.ticketStore.updateProperty}
                    />
                    <hr />
                    <div className="modal-btn-tickets">
                      <MyButton
                        className="btn cancel-btn"
                        text="Cancel"
                        type="button"
                        onClick={() => {
                          this.props.ticketStore.toggleModal("links");
                          this.props.ticketStore.updateProperty("linkText", "");
                          this.props.ticketStore.updateProperty(
                            "urlForTicket",
                            ""
                          );
                        }}
                      />
                      <MyButton
                        className="btn accept-btn"
                        text="Add"
                        type="button"
                        onClick={() => {
                          this.props.ticketStore.addWebLink();
                          this.props.ticketStore.toggleModal("links");
                          this.props.ticketStore.updateProperty("linkText", "");
                          this.props.ticketStore.updateProperty(
                            "urlForTicket",
                            ""
                          );
                        }}
                        links
                      />
                    </div>
                  </form>
                </div>
              </section>
            </div>
            <div
              id="modal-div-2"
              className={this.props.ticketStore.displayModal.subtasks}
            >
              <section className="modal-main">
                <div className="card container">
                  <form className="justify-content-center">
                    <p className="invalid" style={{ marginTop: "1rem" }}>
                      {"Write the task you want to add"}
                    </p>
                    <MyInput
                      visible={true}
                      name="taskTitle"
                      placeholder={"Subtask title"}
                      value={this.props.ticketStore.taskTitle}
                      onChange={this.props.ticketStore.updateProperty}
                    />
                    <hr />
                    <div className="modal-btn-tickets">
                      <MyButton
                        className="btn cancel-btn"
                        text="Cancel"
                        type="button"
                        onClick={() => {
                          this.props.ticketStore.toggleModal("subtasks");
                          this.props.ticketStore.updateProperty(
                            "taskTitle",
                            ""
                          );
                        }}
                      />
                      <MyButton
                        className="btn accept-btn"
                        text="Add"
                        type="button"
                        onClick={() => {
                          this.props.ticketStore.addSubtasks();
                          this.props.ticketStore.toggleModal("subtasks");
                          this.props.ticketStore.updateProperty(
                            "taskTitle",
                            ""
                          );
                        }}
                      />
                    </div>
                  </form>
                </div>
              </section>
            </div>
            <div
              id="modal-div-2"
              className={this.props.ticketStore.displayModal.tags}
            >
              <section className="modal-main">
                <div className="card container">
                  <form className="justify-content-center">
                    <p className="invalid" style={{ marginTop: "1rem" }}>
                      {"Write the tags that you want to add"}
                    </p>
                    <MyInput
                      visible={true}
                      name="tags"
                      placeholder={"id of the issue"}
                      value={this.props.ticketStore.tags}
                      onChange={this.props.ticketStore.updateProperty}
                    />
                    <hr />
                    <div className="modal-btn-tickets">
                      <MyButton
                        className="btn cancel-btn"
                        text="Cancel"
                        type="button"
                        onClick={() => {
                          this.props.ticketStore.toggleModal("tags");
                        }}
                      />
                      <MyButton
                        className="btn accept-btn"
                        text="Add"
                        type="button"
                        onClick={() => {
                          this.props.ticketStore.addTags();
                          this.props.ticketStore.toggleModal("tags");
                        }}
                      />
                    </div>
                  </form>
                </div>
              </section>
            </div>
            <div
              id="modal-div-2"
              className={this.props.ticketStore.displayModal.mail}
            >
              <section className="modal-main">
                <div className="card container">
                  <form className="justify-content-center">
                    <p className="invalid" style={{ marginTop: "1rem" }}>
                      {"Send email"}
                    </p>
                    <div style={{ gap: "1rem", display: "flex" }}>
                      <p style={{ marginBottom: "0rem", minWidth: "5rem" }}>
                        Subject
                      </p>
                      <MyInput
                        visible={true}
                        name="mail.subject"
                        placeholder={"Insereixi el títol"}
                        value={this.props.ticketStore.mail.subject}
                        onChange={this.props.ticketStore.updateNestedProperty}
                      />
                    </div>

                    <div style={{ gap: "1rem", display: "flex" }}>
                      <p style={{ marginBottom: "0rem", minWidth: "5rem" }}>
                        Message
                      </p>
                      <MyInput
                        visible={true}
                        name="mail.body"
                        placeholder={"Insereixi el cos del missatge"}
                        value={this.props.ticketStore.mail.body}
                        onChange={this.props.ticketStore.updateNestedProperty}
                      />
                    </div>

                    <hr />
                    <div className="modal-btn-tickets">
                      <MyButton
                        className="btn cancel-btn"
                        text="Cancel"
                        type="button"
                        onClick={() => {
                          this.props.ticketStore.toggleModal("mail");
                        }}
                      />
                      <MyButton
                        className="btn accept-btn"
                        text="Add"
                        type="button"
                        onClick={() => {
                          this.props.ticketStore.sendMail();
                          this.props.ticketStore.toggleModal("mail");
                        }}
                      />
                    </div>
                  </form>
                </div>
              </section>
            </div>
          </div>
          <div
            style={{
              minWidth: "75%",
              marginTop: "2rem",
              gap: "2rem",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {this.props.ticketStore.ticket.source === "email" ? (
                <svg
                  width="21"
                  height="31"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ "min-width": "5%" }}
                >
                  <path
                    d="M26.6001 22.25V8.75C26.6001 7.505 25.6621 6.5 24.5001 6.5H4.88613C3.72413 6.5 2.78613 7.505 2.78613 8.75V22.25C2.78613 23.495 3.72413 24.5 4.88613 24.5H24.5001C25.6621 24.5 26.6001 23.495 26.6001 22.25ZM24.7661 8.585C25.2281 9.08 24.9761 9.59 24.7241 9.845L19.0401 15.425L24.5001 21.515C24.6681 21.725 24.7801 22.055 24.5841 22.28C24.4021 22.52 23.9821 22.505 23.8001 22.355L17.6821 16.76L14.6861 19.685L11.7041 16.76L5.58613 22.355C5.40413 22.505 4.98413 22.52 4.80213 22.28C4.60613 22.055 4.71813 21.725 4.88613 21.515L10.3461 15.425L4.66213 9.845C4.41013 9.59 4.15813 9.08 4.62013 8.585C5.08213 8.09 5.55813 8.33 5.95013 8.69L14.6861 16.25L23.4361 8.69C23.8281 8.33 24.3041 8.09 24.7661 8.585Z"
                    fill="black"
                  />
                </svg>
              ) : this.props.ticketStore.ticket.source === "app" ? (
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M35.0002 18.3334L33.9585 16.0417L31.6668 15L33.9585 13.9584L35.0002 11.6667L36.0418 13.9584L38.3335 15L36.0418 16.0417L35.0002 18.3334ZM26.6668 26.6667L24.5835 22.0834L20.0002 20L24.5835 17.9167L26.6668 13.3334L28.7502 17.9167L33.3335 20L28.7502 22.0834L26.6668 26.6667ZM35.0002 28.3334L33.9585 26.0417L31.6668 25L33.9585 23.9584L35.0002 21.6667L36.0418 23.9584L38.3335 25L36.0418 26.0417L35.0002 28.3334ZM11.6668 38.3334C10.7502 38.3334 9.96572 38.0072 9.3135 37.355C8.66016 36.7017 8.3335 35.9167 8.3335 35V5.00002C8.3335 4.08335 8.66016 3.29835 9.3135 2.64502C9.96572 1.9928 10.7502 1.66669 11.6668 1.66669H28.3335C29.2502 1.66669 30.0352 1.9928 30.6885 2.64502C31.3407 3.29835 31.6668 4.08335 31.6668 5.00002V11.6667H28.3335V10H11.6668V30H28.3335V28.3334H31.6668V35C31.6668 35.9167 31.3407 36.7017 30.6885 37.355C30.0352 38.0072 29.2502 38.3334 28.3335 38.3334H11.6668Z"
                    fill="black"
                  />
                </svg>
              ) : (
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_313_18)">
                    <path
                      d="M3.33333 35C2.41667 35 1.63222 34.6739 0.98 34.0217C0.326667 33.3683 0 32.5833 0 31.6667V8.33333C0 7.41667 0.326667 6.63167 0.98 5.97833C1.63222 5.32611 2.41667 5 3.33333 5H36.6667C37.5833 5 38.3683 5.32611 39.0217 5.97833C39.6739 6.63167 40 7.41667 40 8.33333V31.6667C40 32.5833 39.6739 33.3683 39.0217 34.0217C38.3683 34.6739 37.5833 35 36.6667 35H3.33333ZM15 23.3333C16.3889 23.3333 17.5694 22.8472 18.5417 21.875C19.5139 20.9028 20 19.7222 20 18.3333C20 16.9444 19.5139 15.7639 18.5417 14.7917C17.5694 13.8194 16.3889 13.3333 15 13.3333C13.6111 13.3333 12.4306 13.8194 11.4583 14.7917C10.4861 15.7639 10 16.9444 10 18.3333C10 19.7222 10.4861 20.9028 11.4583 21.875C12.4306 22.8472 13.6111 23.3333 15 23.3333ZM30.4583 28.9583C30.7639 29.2639 31.1528 29.4028 31.625 29.375C32.0972 29.3472 32.4861 29.1806 32.7917 28.875L33.9583 27.7083C34.2639 27.4028 34.4306 27.0417 34.4583 26.625C34.4861 26.2083 34.375 25.8333 34.125 25.5L33 24C32.8611 23.7778 32.6733 23.6111 32.4367 23.5C32.2011 23.3889 31.9444 23.3333 31.6667 23.3333H29.75C29.5833 22.8333 29.4444 22.2983 29.3333 21.7283C29.2222 21.1594 29.1667 20.5833 29.1667 20C29.1667 19.4167 29.2222 18.8544 29.3333 18.3133C29.4444 17.7711 29.5833 17.2222 29.75 16.6667H31.6667C31.9444 16.6667 32.2011 16.6111 32.4367 16.5C32.6733 16.3889 32.8611 16.2222 33 16L34.125 14.5C34.375 14.1667 34.4861 13.7917 34.4583 13.375C34.4306 12.9583 34.2639 12.5972 33.9583 12.2917L32.7917 11.125C32.4861 10.8194 32.0972 10.6528 31.625 10.625C31.1528 10.5972 30.7639 10.7361 30.4583 11.0417C29.2917 12.2083 28.3683 13.5767 27.6883 15.1467C27.0072 16.7156 26.6667 18.3333 26.6667 20C26.6667 21.6667 27.0072 23.2844 27.6883 24.8533C28.3683 26.4233 29.2917 27.7917 30.4583 28.9583ZM3.5 31.6667H26.5C25.3333 29.5833 23.7222 27.9511 21.6667 26.77C19.6111 25.59 17.3889 25 15 25C12.6111 25 10.3889 25.59 8.33333 26.77C6.27778 27.9511 4.66667 29.5833 3.5 31.6667Z"
                      fill="black"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_313_18">
                      <rect width="40" height="40" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              )}
              {/*TODO Ficar tooltip per el nom complet*/}
              <p style={{ "min-width": "50%", "margin-bottom": "0px" }}>
                {"(#" + this.props.ticketStore.ticket._id + ") " + title}
              </p>
              <div style={{ display: "flex", "min-width": "25%", gap: "1rem" }}>
                <svg
                  width="23"
                  height="37.5"
                  viewBox="0 0 23 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="ticket-title-buttons"
                  onClick={() => {
                    this.props.ticketStore.toggleModal("edit");
                  }}
                >
                  <path
                    d="M16.5419 2.98065C16.1677 2.98147 15.7973 3.04922 15.4522 3.17998C15.107 3.31074 14.794 3.50193 14.5311 3.74253L3.58905 13.625L3.54614 13.8207L2.78914 17.2582L2.57324 18.1757L3.58974 17.9807L7.39554 17.2969L7.61143 17.2575L18.5535 7.37503C18.8205 7.13798 19.0327 6.85526 19.1775 6.54337C19.3223 6.23148 19.3968 5.89667 19.3968 5.55846C19.3968 5.22026 19.3223 4.88545 19.1775 4.57356C19.0327 4.26167 18.8205 3.97895 18.5535 3.7419C18.2904 3.50135 17.9772 3.31023 17.632 3.17957C17.2867 3.04891 16.9163 2.98131 16.5419 2.98065V2.98065ZM16.5419 4.1719C16.8907 4.1719 17.2422 4.31565 17.5799 4.62128C18.2532 5.22878 18.2532 5.88815 17.5799 6.49628L17.083 6.92565L15.0279 5.07003L15.504 4.62128C15.8424 4.31565 16.1932 4.1719 16.5419 4.1719V4.1719ZM14.0557 5.9494L16.1094 7.80503L7.74152 15.3625C7.28977 14.5642 6.57056 13.915 5.68639 13.5075L14.0557 5.9494ZM4.80068 14.5238C5.21021 14.6726 5.58221 14.8946 5.89435 15.1766C6.20649 15.4585 6.45232 15.7945 6.61708 16.1644L4.34605 16.5744L4.80068 14.5238Z"
                    fill="#707070"
                  />
                </svg>
                <svg
                width="20"
                height="36"
                  id="fas_fa-star"
                  data-name="fas fa-star"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 66.62 63.69"
                  className={
                    this.props.ticketStore.ticket.assignedUser && this.props.ticketStore.ticket.assignedUser.favourite === true
                      ? "ticket-title-buttons activeFav"
                      : "ticket-title-buttons nActiveFav"
                  }
                  onClick={() => {
                    this.props.ticketStore.toggleFav();
                  }
                  
                  }
                >
                  <path
                    className="cls-1"
                    d="M51.87,69.13c-.78-.45-1.57-.86-2.38-1.25A16.65,16.65,0,0,0,47.87,67c-1.3-.71-2.61-1.41-3.93-2.06-.72-.41-1.45-.79-2.19-1.15s-1.19-.67-1.82-.94a14.72,14.72,0,0,0-1.76.92c-.77.36-1.52.75-2.25,1.18-1.33.65-2.63,1.35-3.93,2a15.78,15.78,0,0,0-1.47.77c-.89.44-1.77.89-2.63,1.39-1.32.65-2.63,1.33-3.92,2a11.05,11.05,0,0,1-1.23.52,4,4,0,0,1-4.54-2.17,3.68,3.68,0,0,1-.42-1.69c0-.28.07-.55.12-.82.17-1.06.35-2.12.53-3.18l.12-.61c.18-1.13.37-2.26.55-3.39.28-1.3.48-2.62.7-3.94a10,10,0,0,0,.25-1.74s.06-.07.07-.11c.12-.72.23-1.43.35-2.15.17-.9.33-1.81.5-2.72a.26.26,0,0,0-.1-.25L20,48.14l-.2-.2c-1.23-1.23-2.47-2.45-3.74-3.64a2.24,2.24,0,0,0-.44-.43c-1.16-1.19-2.37-2.33-3.57-3.48a1.44,1.44,0,0,0-.38-.36C10.46,38.84,9.27,37.65,8,36.51L7.56,36a4.09,4.09,0,0,1-.71-4,4.53,4.53,0,0,1,1.26-1.73,4.46,4.46,0,0,1,2.06-.82L12,29.18c1.34-.16,2.67-.36,4-.59,1.11-.12,2.2-.28,3.3-.48A1.78,1.78,0,0,0,20,28l1.18-.14L24,27.43c1.34-.16,2.68-.35,4-.58a.28.28,0,0,0,.32-.17c.4-.84.81-1.68,1.21-2.51.71-1.35,1.37-2.72,2-4.09A8.26,8.26,0,0,0,32.06,19c.53-.95,1-1.93,1.45-2.92l1.94-3.92L36,11a6.53,6.53,0,0,1,1.12-1.72A3.92,3.92,0,0,1,40.55,8.2a1.06,1.06,0,0,0,.25,0,4.12,4.12,0,0,1,2.34,1.55c.29.4.46.88.73,1.29a9.47,9.47,0,0,0,.54,1.11c.63,1.35,1.3,2.68,2,4,.45,1,1,2,1.46,3l.51,1,1.92,3.92c.42.85.84,1.7,1.25,2.56.07.14.16.23.33.2,1.34.23,2.67.42,4,.58.37.06.74.13,1.11.18l2.9.4a4.33,4.33,0,0,0,1,.15c1,.19,1.93.33,2.91.43l.64.12,3.37.47.39.07a7,7,0,0,1,3.61,1.1A4.77,4.77,0,0,1,73,32a4,4,0,0,1-.72,4l-.45.49c-1.23,1.13-2.42,2.31-3.6,3.5a1.48,1.48,0,0,0-.4.39c-1.24,1.16-2.45,2.35-3.64,3.55a1.27,1.27,0,0,0-.37.36c-1.27,1.19-2.51,2.4-3.74,3.63l-.2.2L59,49c-.06.06-.12.12-.1.22.17.92.34,1.83.5,2.75s.26,1.65.42,2.48L60.06,56c.22,1.31.41,2.62.69,3.92s.43,2.67.68,4l.64,4c0,.24,0,.48-.1.72a4,4,0,0,1-5.09,3,7.35,7.35,0,0,1-1-.43C54.56,70.5,53.23,69.8,51.87,69.13Z"
                    transform="translate(-6.62 -8.15)"
                  />
                </svg>
                
                <svg
                  width="21"
                  height="37.5"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="ticket-title-buttons"
                  onClick={() => {
                    this.props.ticketStore.toggleModal("tags");
                  }}
                >
                  <g clip-path="url(#clip0_161_538)">
                    <path
                      d="M18.8928 8.9375L11.1428 1.67187C10.8457 1.40946 10.4509 1.26646 10.0428 1.27344H2.54282C2.33658 1.27189 2.13205 1.30864 1.94103 1.38156C1.75002 1.45448 1.57629 1.56214 1.42987 1.69831C1.28345 1.83449 1.16724 1.9965 1.08794 2.17499C1.00864 2.35348 0.96781 2.54492 0.967817 2.73828V9.76953C0.957065 10.1562 1.10984 10.5311 1.39282 10.8125L9.14282 18.0781C9.28755 18.2128 9.45922 18.3194 9.64797 18.3918C9.83673 18.4642 10.0389 18.501 10.2428 18.5C10.4548 18.5046 10.6656 18.4697 10.863 18.3973C11.0604 18.3248 11.2405 18.2164 11.3928 18.0781L18.8928 11.0469C19.041 10.9086 19.1585 10.7442 19.2388 10.5632C19.319 10.3822 19.3603 10.1882 19.3603 9.99219C19.3603 9.79619 19.319 9.60213 19.2388 9.42113C19.1585 9.24014 19.041 9.07578 18.8928 8.9375ZM10.2678 17.0117L2.53032 9.78125V2.75H10.0303L17.7428 9.98047L10.2678 17.0117Z"
                      fill="#787878"
                    />
                    <path
                      d="M6.33037 8.05859C7.30377 8.05859 8.09287 7.27159 8.09287 6.30078C8.09287 5.32997 7.30377 4.54297 6.33037 4.54297C5.35697 4.54297 4.56787 5.32997 4.56787 6.30078C4.56787 7.27159 5.35697 8.05859 6.33037 8.05859Z"
                      fill="#787878"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_161_538">
                      <rect
                        width="20"
                        height="18.75"
                        fill="white"
                        transform="translate(0.142822 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <svg
                  width="21"
                  height="37.5"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="ticket-title-buttons"
                  onClick={() => {
                    this.props.ticketStore.toggleModal("links");
                  }}
                >
                  <g clip-path="url(#clip0_169_1389)">
                    <path
                      d="M11.4295 8.93222C10.7469 8.27193 9.82128 7.901 8.85616 7.901C7.89103 7.901 6.96542 8.27193 6.28283 8.93222L3.70866 11.4225C3.02606 12.0831 2.64258 12.9791 2.64258 13.9133C2.64258 14.8475 3.02606 15.7434 3.70866 16.404C4.39126 17.0646 5.31707 17.4357 6.28241 17.4357C7.24775 17.4357 8.17356 17.0646 8.85616 16.404L10.1428 15.1588"
                      stroke="black"
                      stroke-opacity="0.56"
                      stroke-width="3.33333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.85645 11.4225C9.53903 12.0828 10.4647 12.4538 11.4298 12.4538C12.3949 12.4538 13.3205 12.0828 14.0031 11.4225L16.5773 8.93221C17.2599 8.27163 17.6434 7.37569 17.6434 6.44149C17.6434 5.50728 17.2599 4.61134 16.5773 3.95076C15.8947 3.29018 14.9689 2.91907 14.0035 2.91907C13.0382 2.91907 12.1124 3.29018 11.4298 3.95076L10.1431 5.19592"
                      stroke="black"
                      stroke-opacity="0.56"
                      stroke-width="3.33333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_169_1389">
                      <rect
                        width="20"
                        height="19.3548"
                        fill="white"
                        transform="translate(0.142578 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                {/*<svg
                  width="21"
                  height="37.5"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="ticket-title-buttons"
                >
                  <path
                    d="M5.35124 4.33068C5.01972 4.33068 4.70177 4.45812 4.46735 4.68498C4.23293 4.91184 4.10124 5.21953 4.10124 5.54035V14.8146C4.10124 15.1354 4.23293 15.4431 4.46735 15.6699C4.70177 15.8968 5.01972 16.0242 5.35124 16.0242H14.9346C15.2661 16.0242 15.584 15.8968 15.8185 15.6699C16.0529 15.4431 16.1846 15.1354 16.1846 14.8146V11.5887C16.1846 11.3749 16.2724 11.1697 16.4286 11.0185C16.5849 10.8673 16.7969 10.7823 17.0179 10.7823C17.2389 10.7823 17.4509 10.8673 17.6072 11.0185C17.7634 11.1697 17.8512 11.3749 17.8512 11.5887V14.8146C17.8512 15.5631 17.5439 16.2811 16.997 16.8104C16.45 17.3398 15.7081 17.6371 14.9346 17.6371H5.35124C4.57769 17.6371 3.83582 17.3398 3.28884 16.8104C2.74186 16.2811 2.43457 15.5631 2.43457 14.8146V5.54035C2.43457 4.79176 2.74186 4.07383 3.28884 3.54449C3.83582 3.01515 4.57769 2.71777 5.35124 2.71777H8.68457C8.90558 2.71777 9.11755 2.80274 9.27383 2.95398C9.43011 3.10522 9.5179 3.31034 9.5179 3.52423C9.5179 3.73811 9.43011 3.94323 9.27383 4.09447C9.11755 4.24571 8.90558 4.33068 8.68457 4.33068H5.35124ZM10.7679 3.52423C10.7679 3.31034 10.8557 3.10522 11.012 2.95398C11.1683 2.80274 11.3802 2.71777 11.6012 2.71777H17.0179C17.2389 2.71777 17.4509 2.80274 17.6072 2.95398C17.7634 3.10522 17.8512 3.31034 17.8512 3.52423V8.76616C17.8512 8.98005 17.7634 9.18517 17.6072 9.33641C17.4509 9.48765 17.2389 9.57261 17.0179 9.57261C16.7969 9.57261 16.5849 9.48765 16.4286 9.33641C16.2724 9.18517 16.1846 8.98005 16.1846 8.76616V5.471L12.1904 9.33632C12.1135 9.41335 12.0216 9.47479 11.9199 9.51705C11.8182 9.55932 11.7089 9.58156 11.5982 9.58249C11.4876 9.58342 11.3779 9.56302 11.2754 9.52247C11.173 9.48192 11.08 9.42204 11.0017 9.34632C10.9235 9.2706 10.8616 9.18056 10.8197 9.08145C10.7778 8.98234 10.7567 8.87614 10.7577 8.76906C10.7587 8.66198 10.7816 8.55616 10.8253 8.45777C10.869 8.35938 10.9325 8.27039 11.0121 8.196L15.0062 4.33068H11.6012C11.3802 4.33068 11.1683 4.24571 11.012 4.09447C10.8557 3.94323 10.7679 3.73811 10.7679 3.52423Z"
                    fill="#707070"
                  />
                </svg>*/}
                <svg
                  width="21"
                  height="37.5"
                  viewBox="0 0 21 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="ticket-title-buttons"
                  onClick={() => {
                    this.props.ticketStore.toggleModal("mail");
                  }}
                >
                  <path
                    d="M19.1428 15V6C19.1428 5.17 18.4728 4.5 17.6428 4.5H3.63281C2.80281 4.5 2.13281 5.17 2.13281 6V15C2.13281 15.83 2.80281 16.5 3.63281 16.5H17.6428C18.4728 16.5 19.1428 15.83 19.1428 15ZM17.8328 5.89C18.1628 6.22 17.9828 6.56 17.8028 6.73L13.7428 10.45L17.6428 14.51C17.7628 14.65 17.8428 14.87 17.7028 15.02C17.5728 15.18 17.2728 15.17 17.1428 15.07L12.7728 11.34L10.6328 13.29L8.50281 11.34L4.13281 15.07C4.00281 15.17 3.70281 15.18 3.57281 15.02C3.43281 14.87 3.51281 14.65 3.63281 14.51L7.53281 10.45L3.47281 6.73C3.29281 6.56 3.11281 6.22 3.44281 5.89C3.77281 5.56 4.11281 5.72 4.39281 5.96L10.6328 11L16.8828 5.96C17.1628 5.72 17.5028 5.56 17.8328 5.89Z"
                    fill="#707070"
                  />
                </svg>
              </div>
              <div style={{ display: "flex", gap: "1rem" }}>
                <MyButton
                  id="reassign-btn"
                  type="submit"
                  className="btn form-button-able"
                  text="Reassignar"
                  onClick={() => {
                    this.props.ticketStore.reassign();
                  }}
                />

                <MyButton
                  id="flt-progress"
                  className={"btn btn-outline-info flt-occupancy"}
                  onClick={() => {
                    this.props.ticketStore.updateVisibleFilter("progress");
                  }}
                  type="button"
                  text={this.props.ticketStore.ticket.status}
                />
              </div>
              <div
                id="progress-form"
                className={
                  "card progress-form" +
                  (this.props.ticketStore.visibleFilter === "progress"
                    ? " display-block"
                    : " display-none")
                }
              >
                <p>Change Status</p>
                <hr />
                <form>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <MyButton
                      id="button-filter-save-newness"
                      type="button"
                      className="btn status-btn"
                      text={"waiting"}
                      onClick={() =>
                        this.props.ticketStore.updateProgress("waiting")
                      }
                    />
                    <MyButton
                      id="button-filter-save-newness"
                      type="button"
                      className="btn status-btn"
                      text={"progress"}
                      onClick={() =>
                        this.props.ticketStore.updateProgress("progress")
                      }
                    />
                    <MyButton
                      id="button-filter-save-newness"
                      type="button"
                      className="btn status-btn"
                      text={"pending"}
                      onClick={() =>
                        this.props.ticketStore.updateProgress("pending")
                      }
                    />
                    <MyButton
                      id="button-filter-save-newness"
                      type="button"
                      className="btn status-btn"
                      text={"escalate"}
                      onClick={() =>
                        this.props.ticketStore.updateProgress("escalate")
                      }
                    />
                    <MyButton
                      id="button-filter-save-newness"
                      type="button"
                      className="btn status-btn"
                      text={"closed"}
                      onClick={() =>
                        this.props.ticketStore.updateProgress("closed")
                      }
                    />
                    <MyButton
                      id="button-filter-save-newness"
                      type="button"
                      className="btn status-btn"
                      text={"solved"}
                      onClick={() =>
                        this.props.ticketStore.updateProgress("solved")
                      }
                    />
                  </div>
                </form>
              </div>
            </div>
            <div>
              <details className="detailBox">
                <summary>
                  <p>
                    {this.props.ticketStore.ticket.user +
                      " va plantejar aquesta sol·licitut a través de " +
                      this.props.ticketStore.ticket.source}
                  </p>
                </summary>
                <hr />
                <p>{this.props.ticketStore.ticket.body}</p>
              </details>
              <details className="detailBox">
                <summary className="summary">
                  <p>{"Petició semblant"}</p>
                  <p
                    onClick={() => {
                      this.props.ticketStore.toggleModal("similar");
                    }}
                  >
                    {"+Add"}
                  </p>
                </summary>
                <hr />
                {this.props.ticketStore.ticket.similar
                  ? this.props.ticketStore.ticket.similar.map((similar, i) => {
                      return (
                        <p
                          key={similar._id + i}
                          onClick={() => {
                            this.props.ticketStore.getTicket(similar._id);
                          }}
                        >
                          {similar._id + "  -  " + similar.subject}
                        </p>
                      );
                    })
                  : null}
              </details>
              <details className="detailBox">
                <summary>
                  <p>{"Web Links"}</p>
                  <p
                    onClick={() => {
                      this.props.ticketStore.toggleModal("links");
                    }}
                  >
                    {"+Add"}
                  </p>
                </summary>
                <hr />
                <div>
                  {this.props.ticketStore.ticket.weblinks
                    ? this.props.ticketStore.ticket.weblinks.map(
                        (weblink, i) => {
                          return (
                            <p key={"link" + i}>
                              {weblink.url + "  -  " + weblink.description}
                            </p>
                          );
                        }
                      )
                    : null}
                </div>
              </details>
              <details className="detailBox">
                <summary>
                  <p>{"Subtasks"}</p>
                  <p
                    onClick={() => {
                      this.props.ticketStore.toggleModal("subtasks");
                    }}
                  >
                    {"+Add"}
                  </p>
                </summary>
                <hr />
                {this.props.ticketStore.ticket.tasks
                  ? this.props.ticketStore.ticket.tasks.map((tasks, i) => {
                      return (
                        <div
                          key={tasks._id + i}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <p
                            style={{
                              minWidth: "10rem",
                              maxWidth: "10rem",
                            }}
                          >
                            {tasks._id + "  -  " + tasks.title}
                          </p>
                          <p>
                            {this.props.ticketStore.transformDateToString(
                              tasks.date
                            )}
                          </p>
                          {/*Checkbox here */}
                          <MyCheckbox
                            id={tasks._id + "-" + i}
                            name={"Checkbox-tasks-" + i}
                            defaultChecked={tasks.done}
                            onChange={(name, id, checked) => {
                              this.props.ticketStore.updateStatusTask(
                                name,
                                id,
                                checked
                              );
                            }}
                          />
                          {/*Edit button here */}
                        </div>
                      );
                    })
                  : null}
              </details>
              <details className="detailBox">
                <summary>
                  <p>{"Activity"}</p>
                </summary>
                <hr />
                {this.props.ticketStore.ticket.activity
                  ? this.props.ticketStore.ticket.activity
                      .reverse()
                      .map((activity, i) => {
                        return (
                          <>
                            <p key={activity._id + i}>
                              {activity.username +
                                "  -  " +
                                activity.type +
                                "  -  " +
                                this.props.ticketStore.transformDateToString(
                                  activity.date
                                )}
                            </p>
                            <p>
                              {activity.original !== undefined &&
                              activity.original !== null
                                ? activity.original + " -> " + activity.change
                                : "None -> " + activity.change}
                            </p>
                            <br />
                          </>
                        );
                      })
                  : null}
              </details>
            </div>
          </div>
          <Robot />
        </div>
      );
    } else return <></>;
  }
}
export default Ticket;
